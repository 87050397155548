import moment from "moment";

export const BACKEND_URL = `${process.env.REACT_APP_API}${process.env.REACT_APP_STAGE}`;

export const USER_CREATE = `${BACKEND_URL}/user`;
export const USER_AUTH = `${BACKEND_URL}/user/auth`;
export const USER_VALIDATE = `${BACKEND_URL}/user/validate`;

export const ACCOUNT_CREATE = `${BACKEND_URL}/account/create`;
export const PROJECT_CREATE = `${BACKEND_URL}/project/create`;
export const INVOICES_GET = `${BACKEND_URL}/invoices/get`;

export const QUICKSIGHT_GET = `${BACKEND_URL}/quicksight`;
export const QUICKSIGHT_CREATE = `${BACKEND_URL}/quicksight_create`;
export const AWS_USER_CREATE = `${BACKEND_URL}/aws_user_create`;

export const ACCOUNT_UPDATE = `${BACKEND_URL}/account/update`;
export const UPDATEDACCOUNT_GET = `${BACKEND_URL}/account/updated/get`;

export const ST_ACCOUNT_CREATE = `${BACKEND_URL}/staccount/create`;
export const ST_ACCOUNT_DELETE = `${BACKEND_URL}/staccount/delete`;

export const ST_ACCOUNT_SIGNUP_DEMO = `${BACKEND_URL}/st/signup/demo`;
export const ST_ACCOUNT_SIGNUP_PROD = `${BACKEND_URL}/st/signup/prod`;

export const CERTIFICATES = `${BACKEND_URL}/certificates`;
export const CERTIFICATES_ENROLLMENT = `${BACKEND_URL}/certificates/enrollment`;

export const RESET_PASSWORD_INVITE = `${BACKEND_URL}/user/password/invite`;
export const USER_RESET_PASSWORD = `${BACKEND_URL}/user/password/reset`;

export const NOTIFICATIONS_CREATE = `${BACKEND_URL}/notification/create`;
export const NOTIFICATIONS_GET = `${BACKEND_URL}/notification/get`;

export const dateFormat = (value) => moment(typeof value === 'number' ? value*1000 : value).format('MMM DD, YYYY');
export const compareDates = (value) => {
    let currentDate = moment(new Date()).format()
    let startDate = moment(value).format()

    // return true if todays date is after the start date 
    return currentDate > startDate
}

export const detailsByPath = function (path) {
    let video = '';
    let desc = '';
    let title = '';
    let descBottom = '';
    switch (path) {
      case 'certification':
        title = 'Certification Page Tutorial';
        video = 'https://vimeo.com/656263057';
        desc = `Please watch this brief overview of the Certification Page and how it works.`;
        descBottom = 'Click the video icon in the top right corner of the page to return to this video.';
        break;
      case 'admin':
        title = 'Admin Page Tutorial';
        video = 'https://vimeo.com/656263155';
        desc = `Please watch this brief overview of the Admin Page and how it works.`;
        descBottom = 'Click the video icon in the top right corner of the page to return to this video.';
        break;
      case 'data':
        title = 'Date Intake Page Tutorial';
        video = 'https://vimeo.com/656262999';
        desc = `Please watch this brief overview of the Data Intake Page and how it works.`;
        descBottom = 'Click the video icon in the top right corner of the page to return to this video.';
        break;
      case 'dashboard':
        video = 'https://vimeo.com/656262912';
        title = 'Intro to the Journey Dashboard';
        desc = `Welcome to your ServiceTrade Journey!<br>Please watch this brief overview of the Main Dashboard and how it works.`;
        descBottom = 'Click the video icon in the top right corner of the page to return to this video.';
        break;
      default:
        break;
    }
    return { video, desc, title, descBottom };
  };